import React from "react";

import Layout from "../components/layout";

const EstimateProjectPage = ({ data, location: { pathname } }) => (
  <Layout headerBgClass="bg-white" hideFooter footerBgClass="bg-white">
    <div className="contact-us-page h-100">
      <section className="bg-white my-n6 py-0 o-hidden">
        <div className="container vh-100">
              <iframe id="typeform-full" width="100%" height="100%" frameborder="0" allow="camera; microphone; autoplay; encrypted-media;" src="https://adamfard.typeform.com/to/O1PGBx"></iframe> 
              <script type="text/javascript" src="https://embed.typeform.com/embed.js"></script>
        </div>
      </section>
    </div>

  </Layout>
);

export default EstimateProjectPage;
